@import "../../styles/mixin.scss";
@import "../../styles/variable.scss";

.offers-main-wrapper {
  padding: 24px;
  background-color: $globalBackground;
  & .offers-title {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: $globalTextColor;
  }
  & .offer-items-main-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & .offer-items-block {
      display: flex;
      justify-content: space-between;
      background: $primaryColor;
      border-radius: 10px;
      margin-bottom: 30px;
      padding: 25px 20px;
      gap: 20px;
      width: calc(50% - 15px);
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;   //for laptop view
        @media #{isPhone()} {
          margin-bottom: 30px;
        }
      }
      &:last-child {
        @media #{isPhone()} {
          margin-bottom: 0px;  //for mobile view
        }
      }
      &:hover {
        background-color: $globalBackground;
        transform: scale(1);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      }
      & .offers-first-section {
        width: 65%;
        & .item-title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 15px;
          color: $globalTextColor;
        }
        & .item-description {
          font-size: 16px;
          line-height: 1.3;
          color: $globalTextColor;
        }
        @media #{isPhone()} {
          width: 100%;
        }
      }
      & .offers-second-section {
        height: auto;
        width: 35%;
        display: flex;
        align-items: center;
        & .offers-img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }
        @media #{isPhone()} {
          width: 100%;
        }
      }
      @media #{isPhone()} {
        width: 100%;
        flex-direction: column;
        // background-color: $globalBackgrou
      }
    }
    @media #{isPhone()} {
      flex-direction: column;
      pointer-events: none;
    }
  }
  & .ats-wrapper {
    & .ats-title {
      font-size: 25px;
      font-weight: 700;
      text-align: center;
    }
    & .ats-description {
        text-align: center;
    }
  }
}
