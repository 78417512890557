@import "../../styles/mixin.scss";
@import "../../styles/variable.scss";
.board-block-main-wrapper {
    background-color: $globalBackground;
   padding: 24px;
    & .board-title {
        font-size: 35px;
        font-weight: bold;
        text-align: center;
        color: $globalTextColor;
        margin-bottom: 30px;
        // padding: 0 24px;
    }
 & .board-section {
    width: 90%;
    height: auto;
    display: block;
    margin: auto;
    & .board-img {
        width: 100%;
        height: auto;
        border-radius: 15px;
        @media #{isPhone()}  {
            height: 18.2rem;
            border-radius: 0;
          }
    }
    @media #{isPhone()}  {
        width: 100%;
        padding: 0;
        margin: 30px 0 0 0;
      }
  }
  @media #{isPhone()} {
    padding: 24px 0;
  }
}
