@import "../../styles/mixin.scss";
@import "../../styles/variable.scss";

.future-plan-main-container {
  background-color: $globalBackground;
  padding: 24px;
  & .plans-title {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: $globalTextColor;
  }
}
.investment-desktop-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  gap: 20px;
}

.investment-item {
  width: 13%;
  min-width: 13%;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: $primaryColor;
  text-align: center;
  // cursor: pointer;
  transition: width 0.2s ease-out;
  position: relative;
  height: 300px; // Adjust this based on your preferred layout
  box-shadow: 0px 0px 80px 5px rgba(20, 31, 57, 0.05);
    border-radius: 10px;

  &:hover {
    background-color: #f5f5f5;
    transform: translateY(-5px); // Slight hover lift effect
    @media #{isPhone()} {
      &:hover {
        background-color: $primaryColor; // Removes the hover background change on mobile
        transform: none; // Removes the hover lift effect on mobile
      }
    }
  }

  .title-circle-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px; // Increased the gap between items
    height: 200px;
    .circle {
      background-color: #e0f7fa;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      font-size: 1.2em;
      font-weight: bold;
      color: #000;
      &.circle-hide {
        margin: 0;
      }
      @media #{isPhone()} {
        margin: 0;
        display: none;
      }
    }

    .title {
      font-size: 1.1em;
      color: #000;
      transform: rotate(90deg);
      transition: font-weight 0.3s ease;
      font-weight: bold;

      &.bold {
        transform: rotate(0deg);
      }
      @media #{isPhone()} {
        transform: none;
        margin: 0;
      }
    }

    &.tab-active {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: auto;
      gap: 10px;
    }
    @media #{isPhone()} {
      height: auto;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
  }

  .description {
    font-size: 0.9em;
    color: #000;
    opacity: 1;
    transition: opacity 0.3s ease;
    @media #{isPhone()} {
      text-align: start;
    }
  }

  &.active {
    background-color: #fff;
    border-color: #b2dfdb;
    height: 300px;
    width: 400px;
    animation-name: slideInUp;
    animation-duration: 400ms;
    animation-timing-function: ease;
    animation-delay: 220ms;
    animation-direction: normal;
    animation-fill-mode: both;
    // @media #{isPhone()} {
    //   display: none;
    // }
  }
  @media #{isPhone()} {
    width: 100%;
    display: block;
    margin: auto;
    height: auto;
    padding: 25px 15px;
    background-color: #fff;
  }
}

.investment-mobile-container {
  display: none;
}

@media #{isPhone()} {
  .investment-desktop-container {
    display: none;
  }
  .investment-mobile-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    pointer-events: none;
  }
}
