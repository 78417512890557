@import "../../styles/variable.scss";
.footer-wrapper {
    background-color: $globalBackground;
    border: 1px solid $primaryColor;
    .footer-text {
        text-align: center;
        font-size: 16px;
        color: white;
        padding: 20px;
        font-weight: 600;
    }
}