@import "../../styles/mixin.scss";
@import "../../styles/variable.scss";

.contact-main-wrapper {
  // margin: 20px 0;
  background-color: $globalBackground;
  padding: 24px;
  & .contact-title {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    color: $globalTextColor;
    margin-bottom: 30px;
  }
  & .contact-form-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    & .form-content {
      background-color: $primaryColor;
      width: 50%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 50px;
      border-radius: 10px;
      color: $globalTextColor;
      & .concern-msg {
        width: 100%;
        margin-bottom: 25px;
         color:  $globalTextColor;
      }
      & .contact-btn-wrapper {
        display: block;
        margin: auto;
        & .contact-btn {
          padding: 10px 22px;
          border-radius: 25px;
          background-color: #fff;
          color: $black;
          font-weight: 700;
          font-size: 16px;
          border: 1px solid $globalBackground;

        &:hover {
          background-color: transparent;
          color: #fff;
        }
        }
      }
      @media #{isPhone()} {
        width: 100%;
        padding: 25px 15px;
      }
      
    }
  }
}

.css-msbelq-MuiInputBase-input-MuiOutlinedInput-input {
  color: $globalTextColor !important;
} 
.css-1pzfmz2-MuiInputBase-input-MuiOutlinedInput-input {
  color: $globalTextColor !important;
}
.css-1ll44ll-MuiOutlinedInput-notchedOutline {
 border-color: $globalTextColor !important;
}
.css-hvjq6j-MuiFormLabel-root-MuiInputLabel-root {
  color: $globalTextColor !important;
}