@import "./styles/fonts.scss";

html,
body,
#root {
  height: 100%;
  font-family: "Inter" !important;
}

* {
  font-family: "Inter" !important;
}

body {
  margin: 0;
}
