@import "../../styles/mixin.scss";
@import "../../styles/variable.scss";

.youtube-video-subblock {
  width: 100%;
//   height: 424px;
  & .youtube-title {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: $globalTextColor;
  }
  & .youtube-main-wrapper {
    position: relative;
    aspect-ratio: 16 / 9;
    // padding-bottom: 56.25%; //mainiting aspect ration
    height: 400px;
    width: 60%;
    margin: auto;
    overflow: hidden;
    @media #{isPhone()} {
      width: 100%;
      height: 250px;
    }
    & .embed-videoframe {
      width: 100%;
      height: 100%;
      @media #{isPhone()} {
        height: 100%;
      }
    }
  }
  @media #{isPhone()} {
    height: auto;
  }
}
