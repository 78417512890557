@import "../../styles/variable.scss";
@import "../../styles/mixin.scss";

// @media (min-width: 600px) {
//   .css-1ygil4i-MuiToolbar-root  {
//     padding: 0 45px !important;
//   }
// }
.mobile-menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & .mobile-title {
    font-weight: bold;
    font-size: 20px;
    color: $white;
  }
}
.nav-wrapper {
  background-color: $globalBackground !important;
  & .header-main-title {
    font-weight: bold;
  }
}
.box-main-wrapper {
  background-color: $globalBackground;
  width: 100%;
  padding: 0 0 0px 0 !important;
  // height: 90%;
  position: relative;
  
  & .box-sub-wrapper {
    height: 70vh;  // Ensure this container fills the entire viewport height
    position: relative;
   
    & .banner-backgroundImmg-wrap {
      background-color: #336b87;
      // background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      // url("../../assets/images/connect_transparent.png");
      background-image:  url("../../assets/images/xyz.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; 
      height: 100%;
      width: 100%;
      color: #fff;
      position: absolute;
      top: 0;  // Aligns the image with the top of the container
      left: 0;  // Aligns the image with the left 
      @media #{isPhone()} {
       background-size: cover;

      }
    }
    & .banner-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // height: 65vh;
      padding: 60px 60px 0 60px;
      position: relative;
      z-index: 100;
      & .banner-first-section {
        flex: 1;
        & .description {
          font-size: 4em;
          color: #fff;
          font-weight: 700;
          line-height: 1.2;
          @media #{isPhone()} {
            font-size: 2.3em;
            margin-top: 0px;
            text-align: center;
          }
        }
      }
      & .banner-second-section {
        width: 30%;
        height: 50vh;
        display: flex;
        align-items: center;
        & .banner-img {
          width: 100%;
          height: auto;
        }
        @media #{isPhone()} {
          width: 90%;
          height: auto;
          margin-top: 30px;
        }
      }
      @media #{isPhone()} {
        flex-direction: column;
        height: auto;
        padding: 30px 10px 0 10px;

      }
    }
    @media #{isPhone()} {
      height: auto;
    }
  }
}
